.myNameButton {
    position: fixed;
    top: 15px;
    left: 4%;
    /* font-size: 1.2vw; */
    font-size: calc(0.3rem + ((1vw - 4.14px)*1.112));
    cursor: pointer;
    text-decoration: none;
    float: left;
    color: #262626;
    filter: none;
    z-index: 21;
}

.myNameButton:hover {
    transition: 0.3s;
    text-decoration: none;
    color: gray;
}

.navigationContainer{
    width: 100%;
    height: calc(20px + 2.5vw);
    position: fixed;
    z-index: 20;
    /* font-size: 1.2vw; */
    font-size: calc(0.3rem + ((1vw - 4.14px)*1.112));
    background-color: rgba(239,239,239,0.6);
    filter: drop-shadow(0px 10px 10px rgba(0,0,0,0.1));
}

.newItem{
    margin-right: 3vw;
    cursor: pointer;
    color: #262626;
    text-decoration: none;
    filter: none;
}

.newItem:hover {
    transition: 0.3s;
    text-decoration: none;
    color: gray;
}

.listContainer {
    height: 100%;
    padding-top: 15px;
    list-style: none;
}

.listItem{ 
    /* top: 50%; */
    float: left;
}

.SNSButton {
    margin-top: -5px;
    margin-right: 3vw;
    color: #262626;
    width: 30;
    font-size: 1.5vw;
    filter: none;
}

.SNSButton:hover {
    transition: 0.3s;
    text-decoration: none;
    color: gray;
}

.pMyNameButton {
    position: fixed;
    font-size: 0.6em;
    left: 0.8rem;
    top: 0.8rem;
    z-index: 1;
    color: #262626;
    text-decoration: none;
}

.pMyNameButton:hover {
    transition: 0.3s;
    color: gray;
    text-decoration: none;
}

.pSNSButton {
    color: white;
    width: 30;
}

.pNewItem {
    font-size: 1.5em;
    cursor: pointer;
    color: white;
    text-decoration: none;
}

.pNewItem:hover{
    text-decoration: none;
    color: gray;
}

.pContactButton {
    font-size: 1.5em;
    cursor: pointer;
    color: white;
    text-decoration: none;
}

.pContactButton:hover {
    text-decoration: none;
    color: gray;
}

.pListContainer {
    z-index: 10;
    list-style: none;
    margin-top: 20%;
    padding: 0px;
}

.pModalOutSide {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    z-index: 10;
    text-align: center;
}

.pListItem {
    margin-top: 30px;
}

.pListItemMini {
    margin-top: 5px;
}

.pNewItemMini{
    font-size: 20px;
    color: #EFEFEF;
}

.pNewItemMini:hover{
    transition: 0.3s;
    text-decoration: none;
    color: gray;
}