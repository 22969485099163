@import url("https://use.typekit.net/urt8xoe.css");

html {
  font-family: 'brandon-grotesque',sans-serif;
  font-size: 30px;
  background-color: #EFEFEF;
}

body{
  width: 100%;
  height: 100%;
}