.closeButton {
  position: absolute;
  font-size: 50px;
  top: 12%;
  right: 12%;
  z-index: 100;
  cursor: pointer;
}

.pCloseButton {
  position: fixed;
  font-size: 30px;
  top: 12%;
  right: 10%;
  z-index: 100;
}

.modalOutSide {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.pModalOutSide {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 20;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  display: flex;
  background-color: white;
  width: 80%;
  height: 80%;
  flex-direction: row-reverse;
  z-index: 2;
  border-radius: 20px;
  overflow: scroll;
}

.pModalContainer {
  position: absolute;
  background-color: #efefef;
  overflow-y: auto;
  top: 10%;
  left: 5%;
  width: 90%;
  height: 80%;
  z-index: 2;
  border-radius: 10px;
}

.right {
  display: flex;
  flex: 1.2;
  flex-direction: column;
  padding: 2em 0;
  justify-content: space-evenly;
}

.scroll {
  padding: 20px;
  z-index: -1;
}

.title {
  color: #262626;
  font-size: 60px;
  font-weight: 600;
}

.pTitle {
  color: #262626;
  font-size: 30px;
  font-weight: 600;
}

.pSubtitle {
  font-size: 22px;
}

.bar {
  width: 50%;
}

.pBar {
  margin-top: 0px;
  width: 100%;
}

.sentence {
  font-size: 20px;
  width: 90%;
  padding-bottom: 20px;
}

.pSentence {
  font-size: 14px;
  width: 100%;
  padding-bottom: 20px;
}

.left {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.pic {
  top: 0%;
  left: 4%;
  width: 100%;
}

.image {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  width: 80%;
}

.pCp_cssslider {
  width: 100%;
  padding-top: 200px; /* 画像の高さ */
  position: relative;
  margin: 1em auto;
  text-align: center;
}
.pCp_cssslider > img {
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.5s;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}
.pCp_cssslider input[name="cp_switch"] {
  display: none;
}
/* サムネイル */
.pCp_cssslider label {
  margin: 15px 5px 0 5px;
  border: 2px solid #ffffff;
  display: inline-block;
  cursor: pointer;
  transition: all 0.5s ease;
  opacity: 0.6;
  border-radius: 3px;
}
.pCp_cssslider label:hover {
  opacity: 0.9;
}
.pCp_cssslider label img {
  display: block;
  width: 60px;
  border-radius: 2px;
}
.pCp_cssslider input[name="cp_switch"]:checked + label {
  border: 2px solid #ff7043;
  opacity: 1;
}
.pCp_cssslider input[name="cp_switch"] ~ img {
  opacity: 0;
}
.pCp_cssslider input[name="cp_switch"]:checked + label + img {
  opacity: 1;
}
