.Page {
    height: 100vh;
    display: flex;
    position: relative;
    flex-direction: column;
    font-size: calc(0.5rem + ((1vw - 4.14px)*1.412));
}

.ImageSide {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-top: 15%;
}

.ImageContainer {
    width: 70%;
}

.SentenceSide {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SentenceContainer {
    width: 85%;
    display: flex;
    flex-direction: column;
}

.Headline {
    display: flex;
    flex: 2;
    font-weight: 600;
    font-size: 14vw;
}

.Line {
    margin-top: -10px;
    border-top: 2px solid #FFAB00;
    width: 20vw;
}

.Paragraph {
    display: flex;
    flex: 2;
    line-height: 1;
    margin-top: 10px;
    height: 100%;
  }
  
  .BtnShowMoreContainer {
    display: flex;
    height: 100%;
    flex: 1;
  }

.btnShowMore {
    display: inline-block;
    width: 100px;
    height:30px;
    text-align: center;
    background-color: #FFAB00;
    font-size: 15px;
    line-height: 28px;
    color: #FFF;
    text-decoration: none;
    border: 2px solid #FFAB00;
    overflow: hidden;
    z-index: 1;
}

.btnShowMore:after{
    width: 100%;
    height: 0;
    content:"";
    position: absolute;
    top: 50%;
    left: 50%;
    background : #EFEFEF;
    opacity: 0;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transition: .2s;
    z-index: -1;
}

.btnShowMore:hover{
    color: #FFAB00;
}

.btnShowMore:hover:after{
    height: 240%;
    opacity: 1;
}

.btnShowMore:active:after{
    height: 340%;
    opacity: 1;
}

.link {
    color: #262626;
}

.link:hover{
    text-decoration: none;
    transition: 0.3s;
    color: gray;
}

.socialAccount{
    color: #262626;
    margin-right: 40px;
}

.socialAccount:hover {
    text-decoration: none;
    color: gray;
}