.wholePage {
  height: 400vh;
  display: flex;
  flex-direction: column;
  background-color: #efefef;
}

.HomeTop {
  height: 100vh;
  display: flex;
  align-items: center;
}

.pHomeTop {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.NameContainer {
  flex: 1;
  text-align: center;
}

.pNameContainer {
  display: flex;
  flex: 1;
  line-height: 1.1rem;
  padding-left: 13%;
}

.MyName {
  font-weight: 900;
  font-style: normal;
  font-size: 7.5vw;
  line-height: 7vw;
}

.Jun {
  margin-right: 20%;
}

.Position {
  margin-top: -1rem;
  font-weight: 500;
  font-size: 3vw;
}

.LogoAnimeContainer {
  flex: 1.2;
  box-sizing: border-box;
  text-align: center;
}

.pLogoAnimeContainer {
  display: flex;
  flex: 1.618;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
}

.LogoAnime {
  fill: none;
  stroke-width: 5px;
  padding: 20px;
  width: 75%;
}

.pLogoAnime {
  display: flex;
  fill: none;
  stroke-width: 4px;
  width: 65%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 20px;
}

.ScrollDown {
  position: absolute;
  bottom: 0.1vh;
  left: 43%;
  text-align: center;
  font-size: 2vw;
}

.Page {
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: row;
}

.ImageSide {
  display: flex;
  flex: 1.1;
  justify-content: center;
  align-items: center;
}

.ImageContainer {
  width: 65%;
}

.SentenceSide {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SentenceContainer {
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: column;
  padding-left: 25vw;
}

.pMyName {
  font-weight: 900;
  font-style: normal;
  font-size: 1.5rem;
}

.pJun {
  margin-left: 5px;
}

.pPosition {
  font-weight: 700;
  font-size: 0.8rem;
}

.Headline {
  display: flex;
  flex: 2;
  font-weight: 700;
  font-size: 8vw;
}

.Line {
  display: flex;
  flex: 1;
  border-top: 5px solid #ffab00;
  width: 15vw;
}

.Paragraph {
  display: flex;
  flex: 3;
  font-size: 1.5vw;
  line-height: 1;
}

.btnShowMoreContainer {
  display: flex;
  flex: 1;
}

.btnShowMore {
  position: absolute;
  display: inline-block;
  width: 10.5vw;
  height: 3.5vw;
  text-align: center;
  background-color: #ffab00;
  font-size: 1.5vw;
  line-height: 3vw;
  color: #fff;
  text-decoration: none;
  border: 2px solid #ffab00;
  overflow: hidden;
  z-index: 1;
}

.btnShowMore:after {
  width: 100%;
  height: 0;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: #efefef;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transition: 0.2s;
  z-index: -1;
}

.btnShowMore:hover {
  color: #ffab00;
}

.btnShowMore:hover:after {
  height: 240%;
  opacity: 1;
}

.btnShowMore:active:after {
  height: 340%;
  opacity: 1;
}

.link {
  color: #262626;
}

.link:hover {
  text-decoration: none;
  transition: 0.3s;
  color: gray;
}
